
import { Component, Prop, Provide, Vue, Watch } from "vue-property-decorator";
@Component({
  components: {},
  computed: {}
})
export default class ProductOptions extends Vue {
  @Prop({ type: String }) getType!: string;
  @Prop({ type: Number, default: () => {} }) readonly isReservation: any;
  @Prop({ type: Object, default: () => {} }) readonly data: any;
  @Prop({ type: String, default: "" }) readonly heightMsg!: string;
  @Prop({ type: String, default: "" }) readonly widthMsg!: string;
  @Prop({ type: Number, default: "" }) readonly advancedCustomShow!: any;
  @Prop({ type: Number, default: "" }) readonly commodityType!: any;
  
  @Prop({ type: Number, default: "" }) readonly indexNum!: any;

  @Provide() dataIndex: number = 0;
  
  @Provide() dataShow: boolean = false;
  @Provide() moveFlg: boolean = false;
  @Provide() dataNo: boolean = true;
  @Provide() iHeight: string = "";
  @Provide() iMinHeight: number = 0;
  @Provide() iMaxHeight: number = 0;
  @Provide() iWidth: string = "";

  @Watch("dataIndex", { immediate: true, deep: false })
  private iValChange(): void {
    const _this: any = this;
    if (_this.data.propertyType == 1 || _this.data.propertyType == 2) {
      if (typeof _this.data.propertyValue == "string") {
        _this.data.propertyValue = JSON.parse(_this.data.propertyValue);
        if (_this.data.propertyType == 1) {
          _this.data.propertyValue.map((item: any) => {
            if (typeof item.commodityUnitImg == "string") {
              item.commodityUnitImg = JSON.parse(item.commodityUnitImg);
            }
          });
        }
      }
    }
    _this.dataCallbackFn();
  }

  @Watch("dataShow", { immediate: true, deep: false })
  private dataShowChange(): void {
    const _this: any = this;
    _this.dataNo = false;
    let a = setTimeout(() => {
      _this.dataNo = true;
      clearTimeout(a);
    }, 500);
    if (_this.dataShow) {
      window.addEventListener("scroll", () => {
        _this.dataHideFn();
      });
    }
  }

  created() {
    const _this: any = this;
  }
  mounted() {
    const _this: any = this;
    if (_this.data.propertyType == 5) {
      const offsetTop = (document as any).getElementById("iHeight").offsetTop;
      const { minHeight, maxHeight } = JSON.parse(_this.data.propertyValue);
      _this.iMinHeight = minHeight;
      _this.iMaxHeight = maxHeight;
      _this.$emit("heightCallback", offsetTop, minHeight, maxHeight);
    } else if (_this.data.propertyType == 7) {
      const offsetTop = (document as any).getElementById("iWidth").offsetTop;
      _this.$emit("widthCallback", offsetTop);
    } else if (_this.data.propertyType == 8) {
      _this.$emit("inputWidthCallback", _this.data.propertyValue);
    } else if (_this.data.propertyType == 9) {
      _this.$emit("inputHeightCallback", _this.data.propertyValue);
    }
  }

  /**
   * @Author HS
   * @Date 2021/9/7 3:49 下午
   * @Description: 切换元素
   * @Params: { number ：index - true } [下标]
   * @Return: null
   */
  dataChangeFn(index: number, type: boolean) {
    const _this: any = this;
    _this.dataIndex = index;
    _this.dataHideFn(type);
  }

  /**
   * @Author HS
   * @Date 2021/9/16 6:47 下午
   * @Description: 隐藏弹窗
   * @Params: null
   * @Return: null
   */
  dataShowFn() {
    const _this: any = this;
    if (_this.$base.isNull(_this.dataNo)) return;
    _this.dataShow = true;
  }
  mouseoverFn(){
    console.log('00000'+this.moveFlg);
    if(this.moveFlg){
      this.dataShow = false;
      this.moveFlg=false;
      console.log('------'+this.moveFlg);
    }
    this.moveFlg=true;
    if(!this.dataShow){
      this.moveFlg=false;
    }
  }
  /**
   * @Author HS
   * @Date 2021/9/16 6:47 下午
   * @Description: 隐藏弹窗
   * @Params: null
   * @Return: null
   */
  dataHideFn(type = true) {
    console.log("type:", type);
    const _this: any = this;
    if (_this.$base.isNull(_this.dataNo)) return;
    if (!type) return;
    _this.dataShow = false;
    _this.moveFlg = false;
  }

  /**
   * @Author HS
   * @Date 2021/7/15 11:52 上午
   * @Description: 数据切换回调
   * @Params: null
   * @Return: null
   */
  dataCallbackFn() {
    const _this: any = this;
    if (_this.data.propertyType == 1 || _this.data.propertyType == 2) {
      let data = _this.data.propertyValue[_this.dataIndex];
      data.propertyId = _this.data.propertyId;
      data.propertyType = _this.data.propertyType;
      _this.$emit("dataCallback", data);
    }
  }
}
